import React from "react";
import { useHistory } from "react-router-dom";

const GetInTouch = () => {
  const history = useHistory();

  const routeChange = () => {
    let path = `/contact`;
    history.push(path);
  };
  return (
    <>
      <section id='cta-area' className='pb90'>
        <div className='container ask-us'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-12 pt20 responsive-get-in-touch'>
              <p>
                GET IN TOUCH <br /> WITH US!
              </p>
              <input
                type='button'
                className='btn-bg-transparent'
                value='Ask our Experts'
                onClick={routeChange}
              />
              {/* <p className='ask-us'> */}
              {/* <Link to='/#' className='btn btn-theme'>
                Ask Our Experts
              </Link> */}
              {/* </p> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GetInTouch;
