import img1 from '../../../assets/img/home5/key-AI-differentiator/card1.png';
import img2 from '../../../assets/img/home5/key-AI-differentiator/card2.png';
import img3 from '../../../assets/img/home5/key-AI-differentiator/card3.png';
import author1 from '../../../assets/img/blog/author.png';

export const BlogData = [
  {
    img: img1,
    heading: 'Customer Experience Strategy',
    list: [
      'Digital strategy',
      'Technology strategy',
      'Content strategy',
      'Personalization strategy',
    ],
    // date: "23",
    // month: "May",
    // para: " There are many variations of Lorem um passages of Lorem Ipsum",
    // author_img: author1,
    // authorName: "John Doe",
    // category: "Business",
  },
  {
    img: img2,
    heading: 'Data Analytics & Intelligence',
    list: [
      'Data & analytics strategy',
      'Visualization & reporting',
      'Governance & management',
      'ETL services',
    ],
    // date: "13",
    // month: "Jun",
    // para: " There are many variations of Lorem um passages of Lorem Ipsum",
    // author_img: author1,
    // authorName: "John Doe",
    // category: "Business",
  },

  {
    img: img3,
    heading: 'AI & Deep Learning',
    list: [
      'Custom AI model development',
      'CV & NLU services',
      'Data science & advance analytics',
      'AI models optimization',
    ],
    //     date: "31",
    //     month: "July",
    //     para: " There are many variations of Lorem um passages of Lorem Ipsum",
    //     author_img: author1,
    //     authorName: "John Doe",
    //     category: "Business",
  },
];
