import React from "react";
import ContactFormHiring from "../Contact/ContactFormHiring";

import hiring from "./../../assets/img/hiring.png";

const Hiring = () => {
  return (
    <>
      <section id='contact'>
        <div className='fluid-container'>
          <div className='row'>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex flex-column align-items-center justify-content-center'>
              <div className='hiring-content-left'>
                <p className='we-are-hiring-title'>We Are Hiring!</p>
                <img src={hiring} alt='hiring-img' />
                <p className='we-are-hiring-explainer'>
                  Finantler is an equal opportunity employer who is always
                  looking for <br /> missionaries to become us. We believe in
                  diversity, grit, ownership,
                  <br /> and curiosity. Can't find any open position to your
                  liking but still <br /> want to join our mission, just attach
                  your CV and send us a message.
                </p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='contact-us-content-right d-flex flex-column align-items-center'>
                <ContactFormHiring />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hiring;
