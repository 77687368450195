import React from "react";

const SectionHeading = (props) => {
  return (
    <>
      <div className='row justify-content-center'>
        <div className='col-lg-9'>
          <div className='section-title-center text-center'>
            {/* <h3 className='pt30'>{props.title}</h3> */}
            <h2>{props.heading}</h2>
            <p>{props.explainer}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionHeading;
