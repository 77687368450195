export const Products2 = [
  {
    id: 0,
    name: 'technology_strategy',
    bannerHeading: 'Technology Strategy',
    bannerExplainer:
      'Simplify the intricate process of adopting new technology, processes, and digital solutions.',
    title:
      'Our team will work with you to assess your current technology landscape, identify gaps and opportunities, and develop a roadmap for implementing technology solutions that will align with your digital strategy.',
    Product_features: [
      'Identify technology that supports your digital transformation goals',
      'Design and implement scalable and secure technology architecture',
      'Stay up-to-date with the latest emerging technologies - AI, ML, and blockchain',
      'Foster a culture of innovation and identify new opportunities for technology innovation',
      'Develop comprehensive technology transformation approach that work',
      'Account for crucial dependencies that are required to enable the transformation',
    ],
    useCases: [
      {
        cardOne: {
          title: 'New Opportunities for Technology Innovation',
          explainer:
            'Financial services - Ideation and prototyping to launch new product.',
        },
        cardTwo: {
          title: 'Emerging Technologies to Improve Outcomes',
          explainer:
            'Healthcare providers - Predictive analytics to identify risks and personalize patient plans.',
        },
        cardThree: {
          title: 'Scalable and Secure Technology Architecture',
          explainer:
            'Retail - Technology architecture to expand online presence and reach new markets.',
        },
      },
    ],
  },

  // 2nd Product
  {
    id: 1,
    name: 'content_strategy',
    bannerHeading: 'Content Strategy',
    bannerExplainer:
      'Forge impactful relationships through compelling content.',
    title:
      'Relentlessly engage consumers to win them and keep them. A well-crafted content strategy not only delivers the volume and quality of content demanded by your audience, but also enables you to generate leads, increase conversions, and lies at the core of any successful digital strategy.',
    Product_features: [
      'Design a customized content strategy aligned to your unique goal and challenges',
      'Develop a targeted content strategy for lead generation and conversion',
      'Ensure that your content is impactful, engaging, and on-brand',
      'Deliver the volume and quality of content your audience demands',
      'Create content that resonates with your audience and build greater credibility',
      'Organize, structure, label, and interconnect content for both editors and visitors',
    ],
    useCases: [
      {
        cardOne: {
          title: 'Lead Generation and Conversion',
          explainer:
            'B2B Software company - Targeting specific customer segment to highlight specific product benefits.',
        },
        cardTwo: {
          title: 'Online Presence and Sales',
          explainer:
            'Fashion retailer - Content plan to engage audience with fashion tips and style inspirations.',
        },
        cardThree: {
          title: 'Brand Awareness and Engagement',
          explainer:
            'Non-profit organization - Powerful stories and testimonials from beneficiaries for increased engagement.',
        },
      },
    ],
  },
  // 3rd Product
  {
    id: 2,
    name: 'personalization_strategy',
    bannerHeading: 'Personalization Strategy',
    bannerExplainer:
      'Truly interact on a personal basis with all of your customers.',
    title:
      'Personalization at scale can be a powerful driver of future growth for your company. By using data and analytics to create highly personalized experiences for customers, you can overhaul your approach to customer value management (CVM). This means maximizing value at every stage of the customer life cycle, from acquisition to cross-sell to retention.',
    Product_features: [
      'Adopt personalization as a practice to impact long-term customer satisfaction',
      'Build the right foundations and operational capabilities for personalization at scale',
      'Start personalized cross-selling with the existing customer data',
      'Identify and collect the right data instead of generating and assembling more data',
      'Put the right tech tools and infrastructure in place to test successfully across the customer base',
      'Tailor messages and metrics to target groups or micro-need states and occasions',
    ],
    useCases: [
      {
        cardOne: {
          title: 'Personalized email Marketing',
          explainer:
            "Fashion retailer - Tailored email marketing based on individual customers' preferences.",
        },
        cardTwo: {
          title: 'Products Recommender System',
          explainer:
            "E-commerce - Recommend products based on a customer's browsing and purchasing history.",
        },
        cardThree: {
          title: 'Personalized Product Offerings',
          explainer:
            "Travel agency -  Personalized travel itineraries based on a customer's travel history and budget.",
        },
      },
    ],
  },
  // 4th Product
  {
    id: 3,
    name: 'da_strategy',
    bannerHeading: 'Data & Analytics Strategy',
    bannerExplainer:
      'Harness predictive insights to anticipate behaviors and connect more closely with your customers.',
    title:
      'Data and analytics are beginning to transform the art and science of customer experience. Our team can help you to gain valuable insights into customer preferences, purchase behaviors, and sentiments to understand customer behavior and improve overall customer satisfaction. Gain the ability to generate tailored experiences by using customer interaction data and other relevant information.',
    Product_features: [
      'Map and track customer behavior across interactions, transactions, and operations',
      'Develop precision-marketing models by recognizing and inferring from behavioral patterns',
      'Receive immediate and personalized signals to take actions “in the moment”',
      'Monitor factors impacting customer satisfaction and business performance',
      'Evaluate the ROI of CX investments and link CX initiatives to business results',
      'Create a comprehensive view of customer satisfaction and value potential for real-time actions',
    ],
    useCases: [
      {
        cardOne: {
          title: 'Customer Experience Optimization',
          explainer:
            'E-commerce - Analyze website traffic and user behavior to streamline the checkout process.',
        },
        cardTwo: {
          title: 'Revenue Management',
          explainer:
            'E-commerce - Analyze customer and sales data to develop dynamic pricing models to maximize revenue.',
        },
        cardThree: {
          title: 'Supply Chain Management',
          explainer:
            'Retail - Analyze supply chain data to identify trends and patterns, and optimize inventory levels.',
        },
      },
    ],
  },
  //   5th Product
  {
    id: 4,
    name: 'visualization_reporting',
    bannerHeading: 'Visualization & Reporting',
    bannerExplainer:
      'Curate data visualizations to make vast amounts of data more accessible and understandable.',
    title:
      'At the heart of every business decision lies data. We offer top-tier data visualization and reporting services to turn your data into actionable insights. Our team of experienced experts will work with you to develop a customized solution that fits your specific needs. Whether you have data in spreadsheets, .csv files, databases, or data warehouses, we have the expertise to connect to your data and help you explore, analyze, and visualize it.',
    Product_features: [
      'Connect to your data no matter where it resides for easy access and analysis',
      'Identify specific actions and continuously monitor their implementation and impact',
      'Generate reports in a clear, concise manner, making it easy to understand and act upon',
      'Choose the appropriate visualization to enable quick and easy understanding',
      'Develop valuable insights and actionable recommendations for informed business decisions',
    ],
    useCases: [
      {
        cardOne: {
          title: 'Customer Journey Mapping',
          explainer:
            "A comprehensive picture of a customer's journey across various touchpoints to identify pain points.",
        },
        cardTwo: {
          title: 'Website Optimization',
          explainer:
            'Optimize website design and user experience by analyzing user behavior and engagement.',
        },
        cardThree: {
          title: 'Social Media Analysis',
          explainer:
            'Visualizing social media trends to gain valuable insights into customer sentiment and preferences.',
        },
      },
    ],
  },

  //   6th Product
  {
    id: 5,
    name: 'etl_services',
    bannerHeading: 'ETL Services',
    bannerExplainer:
      'Efficiently discover, prepare, and combine data for analytics, machine learning, and application development.',
    title:
      'We help businesses in extracting data from multiple sources, transforming it into a homogenous format, and loading it into a data warehouse or other target system. Our ETL services are designed to help you unlock the potential of your data, making it easier to access, analyze, and use in decision-making.',
    Product_features: [
      'Build enhanced data capture capability for B2B and B2C environments',
      'Extract value from new types of data eco-systems and alliances',
      'Organize data in logical layers to generate more meaningful data',
      'Enrich data with additional information or domain knowledge',
      'Streamline data integration, cleansing, migration, and performance optimization processes',
      'Infuse business knowledge to interpret the results and derive concrete business-based solutions',
    ],
    useCases: [
      {
        cardOne: {
          title: 'Real-time Data Analysis',
          explainer:
            'Analyze customer data in real-time to make data-driven decisions that enhance the customer experience.',
        },
        cardTwo: {
          title: 'Omnichannel Integration',
          explainer:
            'Integrate data from various channels and touchpoints, enabling a seamless omnichannel experience.',
        },
        cardThree: {
          title: 'Customer Behavior Data',
          explainer:
            'Extract and transform data from CRM system, website, and social media for customer behavior analysis.',
        },
      },
    ],
  },
  // 7th Product
  {
    id: 6,
    name: 'cvnlu_services',
    bannerHeading: 'CV & NLU Services',
    bannerExplainer:
      'Harness the power of AI to scale and future proof your AI investments.',
    title:
      'Adopt a comprehensive approach and roadmap to scaling enterprise-grade AI for your business. Leverage Natural Language Understanding (NLU) and Computer Vision (CV) technologies to streamline processes, reduce costs, and enhance customer experiences.',
    Product_features: [
      'Automate customer service interactions through natural language chatbots',
      'Monitor social media sentiment to track brand reputation and identify customer trends',
      'Classify images to automate quality control and optimize supply chain processes',
      'Enhance fraud detection and security measures through advanced pattern recognitio',
      'Extract relevant information from documents to improve compliance and reduce errors',
      'Enhance overall operational efficiency and reduce costs through automation',
    ],
    useCases: [
      {
        cardOne: {
          title: 'Improved Customer Retention',
          explainer:
            'Natural language Understanding (NLU) to analyze customer data for personalized recommendations.',
        },
        cardTwo: {
          title: 'Enhanced Employee Productivity',
          explainer:
            'NLP chatbots and voice assistants to automate repetitive tasks and provide real-time feedback.',
        },
        cardThree: {
          title: 'Optimized Supply Chain',
          explainer:
            'CV to analyze images and videos to identify inefficiencies and opportunities to optimize supply chain.',
        },
      },
    ],
  },

  // 8th Product
  {
    id: 7,
    name: 'ds_analytics',
    bannerHeading: 'Data Science & Advance Analytics',
    bannerExplainer:
      'Generate proprietary insights to anticipate complex market dynamics.',
    title:
      'Solve complex and unique business challenges by collaborating with our data science and advance analytics team. Anticipate, adapt and scale the value of your D&A strategy by creating context driven analytics from the modular components of your business. Collaboratively craft business driven data and analytics capabilities to drive business growth.',
    Product_features: [
      'Calibrate strategies and performance aspirations against data in near real time',
      'Unearth otherwise hard to spot growth opportunities and enhance strategic planning',
      'Analyze data to find patterns and gain insights by tracking emerging trends',
      'Connect the data strategy with the analytics strategy to efficiently find the right data',
      'Design effective data governance and make data accessible to teams for data-driven decision-making',
      'Integrate insights from the recommendation engine into the daily work flow',
    ],
    useCases: [
      {
        cardOne: {
          title: 'Customer Churn Prediction',
          explainer:
            'Predict which customers are at risk of churning and identify the drivers behind their potential departure.',
        },
        cardTwo: {
          title: 'Voice of the customer analytics',
          explainer:
            'Collect and analyze customer feedback and use the insights to improve product and service offerings.',
        },
        cardThree: {
          title: 'Predictive customer service',
          explainer:
            'Anticipate customer needs, and proactively offer solutions and support to improve customer satisfaction.',
        },
      },
    ],
  },

  // 9th Product
  {
    id: 8,
    name: 'ai_optimizationn',
    bannerHeading: 'AI models optimization',
    bannerExplainer:
      'Adopt AI augmented automation beyond the surface level with greater agility and scalability.',
    title:
      'We help businesses optimize their AI models to ensure that they are efficient, accurate, and effective in delivering the results you need. Our team of experts has extensive experience in AI optimization, including model selection, hyperparameter tuning, and architecture optimization.',
    Product_features: [
      'Choose AI models tailored to your business requirements, considering factors such as data type and volume, problem complexity, and performance target',
      'Optimize the hyperparameters (learning rate, batch size, and regularization) to improve performance, accuracy, and efficiency.',
      'Optimize architecture of AI models by adjusting layers, selecting activation functions, and using advanced techniques',
      'Interpret the results of your AI model and identify any biases, limitations, or areas for improvement',
      'Perform continuous optimization to ensure that your AI model remains up-to-date, accurate, and relevant',
      'Leverage advanced network analysis and AI techniques to extract insights and discover connections from different data sources',
    ],
    useCases: [
      {
        cardOne: {
          title: 'Chatbot Automation',
          explainer:
            'Optimized AI models (context aware) to automate customer support through chatbots.',
        },
        cardTwo: {
          title: 'Voice Recognition',
          explainer:
            'Optimized AI models for voice recognition to provide a more natural and seamless customer experience.',
        },
        cardThree: {
          title: 'Sentiment Analysis',
          explainer:
            'Optimized sentiment analysis models to monitor and respond quickly to negative social media sentiment.',
        },
      },
    ],
  },
];
