import React from 'react';
import BlogCard from '../Blog/BlogCard';
import { BlogData } from '../Blog/BlogData';
import SectionHeading from '../SectionHeading/SectionHeading';

const Blog = ({ className = '' }) => {
  return (
    <div>
      <section id='blog-area' className={`${className}`}>
        <div className='fluid-container pt40 responsive-differentiator-text'>
          <SectionHeading
            title='Insights Differentiator'
            heading='Unleash your Digital Experience Platform potential & develop Strategies to drive Growth'
            explainer='Finantler empowers enterprises in streamlining their data analysis routines to better understand customer needs, viewpoints, and experiences with their services and products. Our AI enablers allow internal teams to take data-driven actions on issues affecting satisfaction and loyalty to increase customer engagement and drive meaningful outcomes.'
          />
        </div>
        <div className='container'>
          <div className='row py80 responsive-features-padding'>
            {BlogData.map((data, index) => (
              <BlogCard data={data} key={index} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
