import React from 'react';
import { Link } from 'react-router-dom';

function OurServiceCard(props) {
  return (
    <>
      {/* Service Card Start */}
      <div className='col-lg-4 col-md-6 col-sm-12 col-12 '>
        <div className='service-box animation-translate'>
          <hr
            style={{
              width: '100px',
              marginLeft: '0px',
              border: '2px solid #5969c3',
            }}
          ></hr>
          <div className='service-box-inner'>
            <p>{props.data}</p>
          </div>
        </div>
      </div>
      {/* Service Card End */}
    </>
  );
}

export default OurServiceCard;
