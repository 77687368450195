import React from 'react';
// Import Components
import SectionHeading from '../SectionHeading/SectionHeading';
import WorkingProcessCard from '../OurWorkingProcess/WorkingProcessCard';
import { Products2 } from './../Products2';
import './../../../helpers/scroll';

const WorkingProcess = ({ className = '', id }) => {
  return (
    <>
      {/* Work Process Area Start */}
      <section id='work-process' className={`pt30 ${className}`}>
        <div className='container'>
          <SectionHeading title='Use Cases' class='usecases-title' />
          <div className='row justify-content-center'>
            {Products2.map((item) => {
              if (item.id === id) {
                return Object.values(item.useCases[0]).map((feature, index) => {
                  return <WorkingProcessCard data={feature} key={index} />;
                });
              }
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkingProcess;
