export const MenuData = [
  //   {
  //     name: "Home",
  //     href: "#!",
  //     has_children: true,
  //     children: [
  //       {
  //         name: "Home One",
  //         href: "/",
  //         has_children: false,
  //       },
  //       {
  //         name: "Home Two",
  //         href: "/home_two",
  //         has_children: false,
  //       },
  //       {
  //         name: "Home Three",
  //         href: "home_three",
  //         has_children: false,
  //       },
  //       {
  //         name: "Home Four",
  //         href: "/home_four",
  //         has_children: false,
  //       },
  //       {
  //         name: "Home Five",
  //         href: "/home_five",
  //         has_children: false,
  //       },
  //     ],
  //   },
  //   {
  //     name: "Services",
  //     href: "#!",
  //     has_children: true,
  //     children: [
  //       {
  //         name: "Services",
  //         href: "/services",
  //         has_children: false,
  //       },
  //       {
  //         name: "Service Details",
  //         href: "/service_details",
  //         has_children: false,
  //       },
  //     ],
  //   },
  {
    name: 'Home',
    href: '/',
    has_children: false,
  },

  {
    name: 'About Us',
    href: '/about',
    has_children: false,
  },

  // {
  //   name: 'Company',
  //   href: '',
  //   has_children: true,
  //   id: -1,
  //   children: [
  //     {
  //       name: 'About Us',
  //       href: '/about',
  //       has_children: false,
  //     },
  //     {
  //       name: 'Advisory Board',
  //       href: '/advisory-board',
  //       has_children: false,
  //     },
  //   ],
  // },

  // {
  //   name: "Our Products",
  //   href: "/our_products",
  //   has_children: false,
  // },

  {
    name: 'Services',
    href: '',
    has_children: true,
    id: -2,
    children: [
      {
        name: 'Technology Strategy',
        // href: "/products/id_data_extraction",
        href: '/products',
        has_children: false,
        id: 0,
      },
      {
        name: 'Content Strategy',
        // href: "/products/urdu_transliteration",
        href: '/products',
        has_children: false,
        id: 1,
      },
      {
        name: 'Personalization Strategy',
        // href: "/products/signature_verification",
        href: '/products',
        has_children: false,
        id: 2,
      },
      {
        name: 'Data & Analytics Strategy',
        // href: "/products/kyc_documents_data_extraction",
        href: '/products',
        has_children: false,
        id: 3,
      },
      {
        name: 'Visualization & Reporting',
        // href: "/products/facial_recognition",
        href: '/products',
        has_children: false,
        id: 4,
      },
      {
        name: 'ETL Services',
        // href: "/products/governmemt_issued_card_similarity",
        href: '/products',
        has_children: false,
        id: 5,
      },

      {
        name: 'CV & NLU Services',
        // href: "/products/contactless_fingerprint",
        href: '/products',
        has_children: false,
        id: 6,
      },

      {
        name: 'Data Science & Advance Analytics',
        // href: "/products/pep_screening_platform",
        href: '/products',
        has_children: false,
        id: 7,
      },

      {
        name: 'AI models optimization',
        // href: "/products/customizable_data_extraction",
        href: '/products',
        has_children: false,
        id: 8,
      },
    ],
  },

  {
    name: 'Ask Our Experts',
    href: '/contact',
    has_children: false,
  },
  // {
  //   name: "Careers",
  //   href: "/careers",
  //   has_children: false,
  // },
  {
    name: 'We are Hiring',
    href: '/we_are_hiring',
    has_children: false,
  },

  //   {
  //     name: "About Us",
  //     href: "/about",
  //     has_children: false,
  //   },
  //   {
  //     name: "Projects",
  //     href: "#!",
  //     has_children: true,
  //     children: [
  //       {
  //         name: "Projects",
  //         href: "/projects",
  //         has_children: false,
  //       },
  //       {
  //         name: "Project Details",
  //         href: "/project_details",
  //         has_children: false,
  //       },
  //     ],
  //   },
  //   {
  //     name: "Blog",
  //     href: "#!",
  //     has_children: true,
  //     children: [
  //       {
  //         name: " Blog",
  //         href: "/blog",
  //         has_children: false,
  //       },
  //       {
  //         name: "Blog Details",
  //         href: "/blog_details",
  //         has_children: false,
  //       },
  //     ],
  //   },
  //   {
  //     name: "Pages",
  //     href: "#!",
  //     has_children: true,
  //     children: [
  //       {
  //         name: "Shop",
  //         href: "/shop",
  //         has_children: false,
  //       },
  //       {
  //         name: "Single Product",
  //         href: "/product_details",
  //         has_children: false,
  //       },
  //       {
  //         name: "Cart",
  //         href: "/cart",
  //         has_children: false,
  //       },
  //       {
  //         name: "Checkout",
  //         href: "/checkout",
  //         has_children: false,
  //       },
  //       {
  //         name: "Login",
  //         href: "/login",
  //         has_children: false,
  //       },
  // {
  //   name: "Contact Us",
  //   href: "/contact",
  //   has_children: false,
  // },
  //       {
  //         name: "FAQ",
  //         href: "/faqs",
  //         has_children: false,
  //       },
  //       {
  //         name: "404 Error",
  //         href: "/error",
  //         has_children: false,
  //       },
  //     ],
  //   },
];
