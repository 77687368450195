import React from 'react';
// Import Components
import SectionHeading from '../SectionHeading/SectionHeading';
import WorkingProcessCard from '../Intro/WorkingProcessCard';
import { WorkProcessData } from './WorkingProcessData';

const Intro = ({ className = '' }) => {
  return (
    <>
      {/* Work Process Area Start */}
      <section id='work-process' className={`py70 ${className}`}>
        <div className='fluid-container'>
          <SectionHeading
            // title='Our Key AI Differentiator:'
            heading='Finantler is the provider of technology platforms engineering since 2018'
            explainer='Finantler specializes in providing customized solutions to help businesses achieve their goals through digital transformation. We understand that every company has unique needs, which is why we offer a wide range of services that are tailored to your specific requirements. We work with top platforms like Sitecore, Salesforce, and Microsoft 365 to provide you with the best possible solutions.'
          />
        </div>
      </section>
    </>
  );
};

export default Intro;
