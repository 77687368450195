import React from 'react';

const SectionHeading = (props) => {
  return (
    <>
      <div className='row justify-content-center'>
        <div className='col-lg-12'>
          <div className='section-title-center pb30'>
            <p className={`pb10 ${props.class}`}>{props.title}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionHeading;
