import Icon1 from '../../../assets/img/service/1.png';
import Icon2 from '../../../assets/img/service/2.png';
import Icon3 from '../../../assets/img/service/3.png';
import Icon4 from '../../../assets/img/service/4.png';
import Icon5 from '../../../assets/img/service/5.png';
import Icon6 from '../../../assets/img/service/6.png';

export const OurServiceData = [
  {
    img: Icon1,
    heading: 'Data Science',
    paragraph:
      'Identify technology that supports your digital transformation goals.',
    button: 'Read More ...',
  },
  {
    img: Icon2,
    heading: 'Machine Learning',
    paragraph:
      'Design and implement scalable and secure technology architecture.',
    button: 'Read More ...',
  },
  {
    img: Icon3,
    heading: 'Artificial Intelligence',
    paragraph:
      'Stay up-to-date with the latest emerging technologies - AI, ML, and blockchain.',
    button: 'Read More ...',
  },
  {
    img: Icon4,
    heading: 'Data Warehousing',
    paragraph:
      'Foster a culture of innovation and identify new opportunities for technology innovation.',
    button: 'Read More ...',
  },
  {
    img: Icon5,
    heading: 'Data Analytics',
    paragraph:
      'Develop comprehensive technology transformation approach that work.',
    button: 'Read More ...',
  },
  {
    img: Icon6,
    heading: 'Business Intelligence',
    paragraph:
      'Account for crucial dependencies that are required to enable the transformation.',
    button: 'Read More ...',
  },
];
