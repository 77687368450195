import React from "react";
import Banner from "../component/Home/Banner";
import About from "../component/Home/About/AboutOne";
import Blog from "../component/Home/Blog/Blog";
import GetInTouch from "../component/Home/GetInTouch";
import DataCompromise from "../component/Home/DataCompromise";

// import Intro from "../component/Common/Blog";

const Home = () => {
  return (
    <>
      <Banner />
      <Blog />
      <About />
      <DataCompromise />
      <GetInTouch />
    </>
  );
};

export default Home;
