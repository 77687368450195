import React, { useEffect, useState } from 'react';
import Banner from '../component/Products/Banner';
import GetInTouch from '../component/Products/GetInTouch';
import ProductFeatures from '../component/Products/OurProductFeatures/ProductFeatures';
import WorkingProcess from '../component/Products/OurWorkingProcess/WorkingProcess';
import FlowChart from '../component/Products/FlowChart';
import OurService from '../component/Products/OurService/OurServices';
import { useLocation } from 'react-router-dom';

// import Intro from "../component/Common/Blog";

const Products = () => {
  const [pageId, setPageId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setPageId(location.state.id);
    }
  }, [location]);

  return (
    <>
      <Banner id={pageId} />
      <ProductFeatures id={pageId} />
      <OurService id={pageId} />
      {/* <FlowChart id={pageId} /> */}
      <WorkingProcess id={pageId} />
      <GetInTouch />
    </>
  );
};

export default Products;
