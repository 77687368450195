import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";

import { Link, useHistory } from "react-router-dom";

const NavItem = (props) => {
  const history = useHistory();
  const [index, setIndex] = useState(0);

  const handleNavbar = () => {
    document
      .querySelector("#navbarSupportedContent")
      .classList.remove("d-block");

    props.setClick(!props.click);
  };

  const dummyFunction = () => {};

  return (
    <>
      <li
        className='nav-item'
        onClick={
          props.item.id === -1 || props.item.id === -2
            ? dummyFunction
            : handleNavbar
        }>
        <Link
          to={{
            pathname: props.item.href,
            state: {
              id: props.item.id,
            },
          }}
          className='nav-link'>
          {props.item.name}
          {props.item.has_children && (
            <i>
              <FiChevronDown />
            </i>
          )}
        </Link>
        {props.item.has_children && (
          <ul
            className={
              props.item.id && props.item.id !== -2
                ? "sec-dropdown-menu"
                : "dropdown-menu"
            }>
            <div className='row'>
              {props.item.children.map((item, index) => (
                <div
                  className={
                    props.item.id && props.item.id !== -2
                      ? "col-lg-12 col-md-12 col-sm-12 col-12"
                      : "col-lg-4 col-md-6 col-sm-6 col-6"
                  }
                  key={index}>
                  <NavItem
                    item={item}
                    click={props.click}
                    setClick={props.setClick}
                  />
                </div>
              ))}
            </div>
          </ul>
        )}
      </li>
    </>
  );
};

export default NavItem;
