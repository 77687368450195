import React from 'react';
// import ProductFeaturesCards from './ProductFeaturesCards.js';
// import { Products } from '../ProductsData.js';
import { Products2 } from '../Products2.js';

const ProductFeatures = ({ className = '', id }) => {
  return (
    <section id='product-features' className={`${className}`}>
      <div className='container'>
        <div className='row d-flex justify-content-center'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-12 pt-55'>
            {Products2.map((item) => {
              if (item.id === id) {
                return <p className='new-title'>{item.title}</p>;
              }
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductFeatures;
