import React from 'react';
import '../../../../node_modules/react-modal-video/css/modal-video.min.css';
// Img
import banner from '../../../assets/img/home5/banner.png';
// import banner from "../../../assets/img/home5/banner.svg";
import bannerlogo from '../../../assets/img/home5/banner-logo.png';
import { useHistory } from 'react-router-dom';

const Banner = () => {
  const history = useHistory();

  const routeChange = () => {
    let path = `/contact`;
    history.push(path);
  };

  return (
    <>
      <section id='banner-five'>
        {/* <div className="bg-shape"></div> */}
        <div className='fluid-container'>
          <div className='row'>
            <div className='col-lg-8 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center'>
              <div className='home-one-banner-content color-white '>
                <img
                  src={bannerlogo}
                  alt='banner-logo'
                  className='banner-logo-responsive'
                />
                {/* <h1 className='color-white '>Big Data & Analytics</h1>
                <h2 className='color-white '>Innovative Solutions</h2> */}
                <p className='color-white '>
                  Curate leading-edge digital experiences by <br />
                  discovering the insights hidden in your customer data
                </p>
                {/* <ModalVideo
                  channel='youtube'
                  api={false}
                  autoplay
                  isOpen={isOpen}
                  videoId='L61p2uyiMSo'
                  onClose={() => setOpen(false)}
                /> */}
                <div className='banner-one-btn pt30'>
                  <input
                    type='button'
                    className='btn-bg-white'
                    value='Ask our Experts'
                    onClick={routeChange}
                  />
                  {/* <Button className='btn-bg-white'>Ask our Experts</Button> */}
                  {/* <a
                    href='#!'
                    className='banner-video color-white video-link'
                    onClick={() => setOpen(true)}>
                    <AiOutlinePlayCircle /> Ask our Experts
                  </a> */}
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
              <div className='banner-img animation-img-one'>
                <img src={banner} alt='img' className='banner-img' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
