import React, { useState, useRef, useEffect } from "react";
import emailjs from "emailjs-com";
import { Form, Row, Col, InputGroup } from "react-bootstrap";

const ContactForm = () => {
  const form = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [successMessage, setSuccessMess] = useState(false);

  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setSuccessMess(false);
  }, []);

  const sendEmail = (e) => {
    const formNew = e.currentTarget;
    if (formNew.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      setIsVisible(true);
      emailjs
        .sendForm(
          "service_zbsger8",
          "template_9gtep2k",
          form.current,
          "XDeAu5wCZTS2Vr8yZ"
        )
        .then((res) => {
          console.log(res);
          setSuccessMess(true);
          setIsVisible(false);
        })
        .catch((err) => {
          console.log(err);
          setIsVisible(false);
        });
    }

    setValidated(true);
  };

  return (
    <>
      <div className='contact-form-area'>
        <Form ref={form} noValidate validated={validated} onSubmit={sendEmail}>
          <p className='green'>
            {successMessage ? "Form submitted successfully" : ""}
          </p>
          <Row>
            <Form.Group as={Col} md='12' controlId='validationCustom01'>
              <Form.Label>
                Full Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control type='text' name='full_name' required />
              </InputGroup>
              <Form.Control.Feedback type='invalid'>
                Please provide your full name.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md='6' controlId='validationCustom02'>
              <Form.Label>Job Title</Form.Label>
              <Form.Control type='text' name='job_title' />
            </Form.Group>

            <Form.Group as={Col} md='6' controlId='validationCustomUsername'>
              <Form.Label>Company</Form.Label>
              <InputGroup hasValidation>
                <Form.Control type='text' name='company' />
              </InputGroup>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md='6' controlId='validationCustom02'>
              <Form.Label>Industry</Form.Label>
              <Form.Control type='text' name='industry' />
            </Form.Group>

            <Form.Group as={Col} md='6' controlId='validationCustomUsername'>
              <Form.Label>
                Phone Number <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type='text'
                  name='phone_number'
                  className='phone'
                  placeholder='+92-333-1111111'
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid phone number.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md='12' controlId='validationCustomUsername'>
              <Form.Label>
                Email Address <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control type='email' name='email_address' required />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid business email.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md='12' controlId='validationCustom02'>
              <Form.Label>Your Message</Form.Label>
              <Form.Control
                as='textarea'
                placeholder='Leave your message here'
                style={{ height: "50px" }}
              />
            </Form.Group>
          </Row>

          <input
            type='submit'
            className='contact-us-btn'
            value='Submit'
            style={{ cursor: "pointer" }}
          />
        </Form>
      </div>
    </>
  );
};

export default ContactForm;
