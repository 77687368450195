import React from "react";
// import ContactDetails from "../component/Contact";
import Hiring from "../component/Hiring";

const Contact = () => {
  return (
    <>
      {/* <CommonBanner heading="Contact" menu1="Home" menu2="Contact"/> */}
      <Hiring />
    </>
  );
};

export default Contact;
