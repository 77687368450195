import React from "react";

const WorkingProcessCard = (props) => {
  return (
    <>
      <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
        <div className='work-process-item  animation-translate text-center box-shadow use-cases-border-radius'>
          {/* <div className="work-process-img box-shadow ">
                            <img alt="" src={props.data.img} />
                        </div> */}
          {/* <div className='work-process-details '> */}
          <p className='use-cases-card-heading'>{props.data.title}</p>
          <p>{props.data.explainer}</p>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default WorkingProcessCard;
