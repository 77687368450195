import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { AboutOneData } from './AboutOneData';

import img1 from '../../../assets/img/home5/products/imgOne.png';
import img2 from '../../../assets/img/home5/products/imgTwo.png';
import img3 from '../../../assets/img/home5/products/imgThree.png';
import img4 from '../../../assets/img/home5/products/imgFour.png';
import img5 from '../../../assets/img/home5/products/imgFive.png';
import img6 from '../../../assets/img/home5/products/imgSix.png';
import img7 from '../../../assets/img/home5/products/imgSeven.png';
import img8 from '../../../assets/img/home5/products/imgEight.png';
import img9 from '../../../assets/img/home5/products/imgNine.png';

const AboutOne = () => {
  const [index, setIndex] = useState(0);
  const [carouselStart, setCarouselStart] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;
  const [responsiveCarousel, setResponsiveCarousel] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (width < 576) {
      setResponsiveCarousel(true);
    } else {
      setResponsiveCarousel(false);
    }
  }, [width]);

  useEffect(() => {
    const el = document.querySelector('.carousel');
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setCarouselStart(true);
          return;
        }
        setCarouselStart(false);
      },
      {
        root: null,
        threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
      }
    );

    observer.observe(el);
  }, []);

  const handleSelect = (selectedIndex, e) => {
    if (carouselStart) {
      setIndex(selectedIndex);
      autoChnageCarouselHandler(index);
    }
  };

  const autoChnageCarouselHandler = (i) => {
    if (i > 7) {
      let oldElement = document.getElementById(`${i}`);
      oldElement.classList.remove('active');

      let element = document.getElementById('0');
      element.classList.add('active');
    } else {
      let oldElement = document.getElementById(`${i}`);
      oldElement.classList.remove('active');

      let element = document.getElementById(`${i + 1}`);
      element.classList.add('active');
    }
  };

  const carousel_handler = (e) => {
    if (e) {
      if (e.target.id) {
        console.log('Target before ', e.target);
        console.log(index);
        let oldElement = document.getElementById(`${index}`);
        console.log(oldElement);
        oldElement.classList.remove('active');

        // console.log("Index before: ", index);
        // console.log("Id before: ", index);

        setIndex(parseInt(e.target.id));

        // console.log("Index after: ", index);
        // console.log("Id after: ", index);

        let newElement = e.target;
        newElement.classList.add('active');
      }
    }
  };

  const viewProduct = () => {
    history.push({
      pathname: '/products',
      state: { id: index },
    });
  };
  return (
    <>
      {/* About One Area Start */}
      <section id='about' className='py100'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
              <div className='products-header'>
                <p>Services</p>
              </div>
            </div>
          </div>
          <div className='row carousel-responsive'>
            <div className='col-lg-1 col-md-1 col-sm-1 col-xm-12 col-12'>
              {responsiveCarousel === true ? (
                <div className='mobile-version-carousel-handles'>
                  <div
                    id='0'
                    className='rect active'
                    onClick={carousel_handler}
                  ></div>
                  <div id='1' className='rect' onClick={carousel_handler}></div>
                  <div id='2' className='rect' onClick={carousel_handler}></div>
                  <div id='3' className='rect' onClick={carousel_handler}></div>
                  <div id='4' className='rect' onClick={carousel_handler}></div>
                  <div id='5' className='rect' onClick={carousel_handler}></div>
                  <div id='6' className='rect' onClick={carousel_handler}></div>
                  <div id='7' className='rect' onClick={carousel_handler}></div>
                  <div id='8' className='rect' onClick={carousel_handler}></div>
                </div>
              ) : (
                <div className='wrapper responsive-wrapper'>
                  <div className='vertical-line'></div>
                  <div
                    className='circle active'
                    id='0'
                    onClick={carousel_handler}
                  >
                    <p>{index === 0 ? '01' : ''}</p>
                  </div>
                  <div className='vertical-line'></div>
                  <div className='circle' id='1' onClick={carousel_handler}>
                    <p>{index === 1 ? '02' : ''}</p>
                  </div>
                  <div className='vertical-line'></div>
                  <div className='circle' id='2' onClick={carousel_handler}>
                    <p>{index === 2 ? '03' : ''}</p>
                  </div>
                  <div className='vertical-line'></div>
                  <div className='circle' id='3' onClick={carousel_handler}>
                    <p>{index === 3 ? '04' : ''}</p>
                  </div>
                  <div className='vertical-line'></div>
                  <div className='circle' id='4' onClick={carousel_handler}>
                    <p>{index === 4 ? '05' : ''}</p>
                  </div>
                  <div className='vertical-line'></div>
                  <div className='circle' id='5' onClick={carousel_handler}>
                    <p>{index === 5 ? '06' : ''}</p>
                  </div>
                  <div className='vertical-line'></div>
                  <div className='circle' id='6' onClick={carousel_handler}>
                    <p>{index === 6 ? '07' : ''}</p>
                  </div>
                  <div className='vertical-line'></div>
                  <div className='circle' id='7' onClick={carousel_handler}>
                    <p>{index === 7 ? '08' : ''}</p>
                  </div>
                  <div className='vertical-line'></div>
                  <div className='circle' id='8' onClick={carousel_handler}>
                    <p>{index === 8 ? '09' : ''}</p>
                  </div>
                  <div className='vertical-line'></div>
                </div>
              )}
            </div>
            <div className='col-lg-5 col-md-11 col-sm-11 col-xm-12 col-12'>
              <div className='about-details pt120'>
                {AboutOneData.map((data) => {
                  if (data.id === index) {
                    return (
                      <div key={index}>
                        <p className='product-heading'>{data.heading}</p>
                        <p className='product-para'>{data.para}</p>
                      </div>
                    );
                  }
                })}
                <input
                  type='button'
                  className='btn-bg-blue'
                  value='View Details'
                  onClick={viewProduct}
                />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-xm-12 col-12'>
              <Carousel
                controls={false}
                activeIndex={index}
                fade={true}
                onSelect={handleSelect}
                className='carousel'
              >
                <Carousel.Item>
                  <img className='d-block w-100' src={img1} alt='First slide' />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className='d-block w-100'
                    src={img2}
                    alt='Second slide'
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img className='d-block w-100' src={img3} alt='Third slide' />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className='d-block w-100'
                    src={img4}
                    alt='Fourth slide'
                  />
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <img className='d-block w-100' src={img5} alt='Fifth slide' />
                </Carousel.Item>
                <Carousel.Item>
                  <img className='d-block w-100' src={img6} alt='Sixth slide' />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className='d-block w-100'
                    src={img7}
                    alt='Seventh slide'
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className='d-block w-100'
                    src={img8}
                    alt='Eighth slide'
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img className='d-block w-100' src={img9} alt='Ninth slide' />
                </Carousel.Item>
                {/* <Carousel.Item>
                  <img
                    className='d-block w-100'
                    src={img7}
                    alt='Seventh slide'
                  />
                </Carousel.Item> */}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
      {/* About One Area End */}
    </>
  );
};

export default AboutOne;
