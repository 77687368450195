import React, { useState, useRef, useEffect } from "react";
import emailjs from "emailjs-com";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
// import Email fr

const ContactFormHiring = () => {
  const form = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [successMessage, setSuccessMess] = useState(false);
  const [validated, setValidated] = useState(false);
  const [fileUploadText, setFileuploadText] = useState(false);
  const [fileName, setFileName] = useState();

  const [formData, setFormData] = useState({
    full_name: "",
    email_address: "",
    job_title: "",
    phone_number: "",
    cvFile: "",
  });

  useEffect(() => {
    setSuccessMess(false);
  }, []);

  const sendEmail = (e) => {
    const formNew = e.currentTarget;
    console.log("Form Data: ", formData);
    if (formNew.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      setIsVisible(true);
      emailjs
        .send(
          "service_zbsger8",
          "template_sam9elt",
          formData,
          "XDeAu5wCZTS2Vr8yZ"
        )
        .then((res) => {
          console.log(res);
          setSuccessMess(true);
          setIsVisible(false);
        })
        .catch((err) => {
          console.log(err);
          setIsVisible(false);
        });
    }

    setValidated(true);
  };

  const handleFileUpload = () => {
    document.getElementById("selectedFile").click();
  };

  const handleChange = (e) => {
    if (e.target.name === "cvFile") {
      setFileName(e.target.files[0].name);
      setFileuploadText(true);
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const removeFile = () => {
    setFileuploadText(false);
    setFileName();
  };

  return (
    <>
      <div className='contact-form-area mt40'>
        <Form
          method='post'
          encType='multipart/form-data'
          ref={form}
          noValidate
          validated={validated}
          onSubmit={sendEmail}>
          <p className='green'>
            {successMessage ? "Form submitted successfully" : ""}
          </p>
          <Row>
            <Form.Group as={Col} md='12' controlId='validationCustom01'>
              <Form.Label>
                Full Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type='text'
                name='full_name'
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type='invalid'>
                Please provide your full name.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md='12' controlId='validationCustomUsername'>
              <Form.Label>
                Email Address <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type='email'
                  name='email_address'
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid email.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md='6' controlId='validationCustom02'>
              <Form.Label>Job Title</Form.Label>
              <Form.Control
                type='text'
                name='job_title'
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} md='6' controlId='validationCustomUsername'>
              <Form.Label>
                Phone Number <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type='text'
                  name='phone_number'
                  className='phone'
                  placeholder='+92-333-1111111'
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid phone number.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group controlId='formFile' className='mb-3'>
              <input
                type='file'
                name='cvFile'
                id='selectedFile'
                onChange={handleChange}
                accept='application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              />
            </Form.Group>
          </Row>
          {fileUploadText ? (
            <p className='resumebtn'>
              {fileName}
              <span
                style={{ color: "grey", marginLeft: "20px" }}
                onClick={removeFile}>
                x
              </span>
            </p>
          ) : (
            <p className='resumebtn' onClick={handleFileUpload}>
              Upload your resume
            </p>
          )}

          <input
            type='submit'
            className='contact-us-btn'
            value='Submit'
            style={{ cursor: "pointer" }}
          />
        </Form>
      </div>
    </>
  );
};

export default ContactFormHiring;
