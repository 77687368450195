import React from "react";
import ContactForm from "../Contact/Contactform";

import contactImg from "./../../assets/img/home5/contact-us.png";

const ContactDetails = () => {
  return (
    <>
      <section id='contact-us'>
        <div className='fluid-container plr-100'>
          <div className='row py60 contact-page-row'>
            <div className='col-lg-5 col-md-12 col-sm-12 col-12 d-flex flex-column align-items-center'>
              <p className='contact-us-title'>
                It's your turn to empower your organization with powerful
                <br />
                Artificial Intelligence and transform ideas into reality.
              </p>

              <img src={contactImg} alt='contact-us-img' />
              <p className='contact-us-explainer'>
                Our solution architect will walk you through the Finantler
                Products, and in <br /> minutes, you'll see how AI can be
                infused into your mission critical processes to <br /> boost
                revenue, efficiency, CX, and compliance.
              </p>
            </div>
            <div className='col-lg-5 col-md-12 col-sm-12 col-12 responsive-margin'>
              <ContactForm />
            </div>
          </div>
        </div>
        {/* <div className='fluid-container'>
          <div className='row'>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex flex-column align-items-center justify-content-center'>
              <div className='contact-us-content-left'>
                <p className='contact-us-title'>
                  It's your turn to empower your organization with powerful
                  <br />
                  Artificial Intelligence and transform ideas into reality.
                </p>

                <img src={contactImg} alt='contact-us-img' />
                <p className='contact-us-explainer'>
                  Our solution architect will walk you through the Finantler
                  Products, and in <br /> minutes, you'll see how AI can be
                  infused into your mission critical processes to <br /> boost
                  revenue, efficiency, CX, and compliance.
                </p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='contact-us-content-right d-flex flex-column align-items-center'>
                <ContactForm />
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default ContactDetails;
