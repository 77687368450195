import React from 'react';

const Banner = () => {
  return (
    <>
      <section id='about-header'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center'>
              <div className='about-header-content color-white '>
                {/* <img src={bannerlogo} alt='banner-logo' /> */}
                <h2 className='product-features-banner-title '>About Us</h2>
                <p className='product-features-banner-explainer'>
                  Powering your digital transformation with tailored solutions
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
