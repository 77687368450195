import React from "react";
import { Link } from "react-router-dom";
const BlogCard = (props) => {
  return (
    <>
      <div className='col-lg-4 col-md-6 col-sm-12 col-12 padding-responsive'>
        <div className='blog-item single-item-mt-2 animation-translate'>
          <div className='blog-img'>
            <img alt='' src={props.data.img} />
          </div>
          <div className='blog-details'>
            <p className='blog-details-heading'>{props.data.heading}</p>
            <ul className='blog-details-list'>
              {props.data.list.map((item, index) => {
                return (
                  <li key={index}>
                    <p>{props.data.list[index]}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
