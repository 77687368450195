import React from 'react';
// Import Components
import SectionHeading from '../SectionHeading/SectionHeading';
import { OurServiceData } from './OurServicesData';
import OurServiceCard from '../OurService/OurServiceCard';
import { Products2 } from '../Products2';

const OurServices = ({ id }) => {
  return (
    <>
      {/* Service Area Start */}
      <section id='service' className='bg-color'>
        <div className='container'>
          <SectionHeading
            title='We will help you define how to'
            class='new-title'
          />
          <div className='row'>
            {Products2.map((item) => {
              if (item.id === id) {
                return item.Product_features.map((feature, index) => {
                  return <OurServiceCard data={feature} key={index} />;
                });
              }
            })}
            {/* {Products2.map((data, index) => (
  
              <OurServiceCard data={data} key={index} />
            ))} */}
          </div>
        </div>
      </section>
      {/* Service Area End */}
    </>
  );
};

export default OurServices;
