export const AboutOneData = [
  {
    id: 0,
    heading: 'Technology Strategy',
    para: 'Leverage technology to roll out strategies more efficiently and at scale across various markets. Simplify the intricate process of adopting new technology, processes, and digital solutions.',
    button: 'Read More...',
  },
  {
    id: 1,
    heading: 'Content Strategy',
    para: 'Forge impactful relationships through compelling content. Create a digital first content strategy that weaves together a vast amount of digital content into a unified plan and deliver new digital experiences, faster.',
    button: 'Read More...',
  },
  {
    id: 2,
    heading: 'Personalization Strategy',
    para: 'Truly interact on a personal basis with all of your customers. Activate all the customer data available to deliver tailored, meaningful, and relevant customer communication to unlock significant near-term value for business.',
    button: 'Read More...',
  },
  {
    id: 3,
    heading: 'Data & Analytics Strategy',
    para: "Identify customer experience issues and opportunities in real time. Harness predictive insights to anticipate behaviors and connect more closely with your customers. Design a data and analytics strategy that provides a holistic picture of customer's digital behaviors.",
    button: 'Read More...',
  },
  {
    id: 4,
    heading: 'Visualization & Reporting',
    para: 'Storytelling with data visualization. Curate data visualizations to make vast amounts of data more accessible and understandable. Organize and present data to achieve clarity in consuming information and drive well-informed decision- making process in your organization.',
    button: 'Read More...',
  },
  {
    id: 5,
    heading: 'ETL Services',
    para: 'Create insights-based value and achieve business impact with data. Organizations need new data engineering and integration services that scale and grow as business need change. Efficiently discover, prepare, and combine data for analytics, machine learning, and application development.',
    button: 'Read More...',
  },
  {
    id: 6,
    heading: 'CV & NLU Services',
    para: 'Harness the power of AI to scale and future proof your AI investments. Ensure business value before implementing machine learning solutions. We speed up your AI & machine learning projects when needed. Full-scale services from NLP, and recommender engines to computer vision.',
    button: 'Read More...',
  },
  {
    id: 7,
    heading: 'Data Science & Advance Analytics',
    para: 'Advance analytics as a core pillar of innovation. Unearth new growth opportunities and identify early-stage trends by unfolding your business context in real time to trigger big moves before your competitors. Generate proprietary insights to anticipate complex market dynamics.',
    button: 'Read More...',
  },
  {
    id: 8,
    heading: 'AI models optimization',
    para: 'Reengineer organizational decision making by discovering and adapting more quickly to changes. Adopt AI augmented automation beyond the surface level with greater agility and scalability.',
    button: 'Read More...',
  },
];
