import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './../ProductsData.js';
// import { Products } from '../ProductsData.js';
import { Products2 } from '../Products2.js';

const Banner = ({ id }) => {
  const history = useHistory();
  const myRef = useRef();

  useEffect(() => myRef.current.scrollIntoView(), [id]);

  const routeChange = () => {
    let path = `/contact`;
    history.push(path);
  };

  return (
    <>
      <section
        id='product-features-header'
        ref={myRef}
        className={Products2.map((item) =>
          item.id === id ? item.name : ''
        ).join('')}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center'>
              <div className='product-features-banner-content color-white '>
                {Products2.map((item, index) => {
                  if (item.id === id) {
                    return (
                      <div key={index}>
                        <h2 className='product-features-banner-title '>
                          {item.bannerHeading}
                        </h2>
                        <p className='product-features-banner-explainer'>
                          {item.bannerExplainer}
                        </p>
                      </div>
                    );
                  }
                })}
                <div className='banner-one-btn pt30'>
                  <input
                    type='button'
                    className='btn-bg-white'
                    value='Request a demo'
                    onClick={routeChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
