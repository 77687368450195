// Banners
import id_card_extraction_banner from './../../assets/img/products/id_card_extraction_banner.png';
import urdu_transliteration_banner from './../../assets/img/products/urdu_transliteration_banner.png';
import signature_verification_banner from './../../assets/img/products/signature_verification_banner.png';
import kyc_documents_banner from './../../assets/img/products/kyc_documents_banner.png';
import facial_recognition_banner from './../../assets/img/products/facial_recognition_banner.png';
import gov_issued_card_banner from './../../assets/img/products/gov_issued_card_banner.png';
import contactless_fingerprint_banner from './../../assets/img/products/contactless_fingerprint_banner.png';
import pep_screening_banner from './../../assets/img/products/pep_screening_banner.png';
import customizable_data_banner from './../../assets/img/products/id_card_extraction_banner.png';

// Icons
import id_data_extraction_first_icon from './../../assets/icons/single-product/id-data-extraction/icon1.svg';
import id_data_extraction_second_icon from './../../assets/icons/single-product/id-data-extraction/icon2.svg';
import id_data_extraction_third_icon from './../../assets/icons/single-product/id-data-extraction/icon3.svg';

import urdu_transliteration_first_icon from './../../assets/icons/single-product/urdu-transliteration/icon1.svg';
import urdu_transliteration_second_icon from './../../assets/icons/single-product/urdu-transliteration/icon2.svg';
import urdu_transliteration_third_icon from './../../assets/icons/single-product/urdu-transliteration/icon3.svg';

import signature_verification_first_icon from './../../assets/icons/single-product/signature-verification/icon1.svg';
import signature_verification_second_icon from './../../assets/icons/single-product/signature-verification/icon2.svg';
import signature_verification_third_icon from './../../assets/icons/single-product/signature-verification/icon3.svg';

import kyc_documents_first_icon from './../../assets/icons/single-product/kyc-documents-data-extraction/icon1.svg';
import kyc_documents_second_icon from './../../assets/icons/single-product/kyc-documents-data-extraction/icon2.svg';
import kyc_documents_third_icon from './../../assets/icons/single-product/kyc-documents-data-extraction/icon3.svg';

import facial_recognition_first_icon from './../../assets/icons/single-product/facial-recognition/icon1.svg';
import facial_recognition_second_icon from './../../assets/icons/single-product/facial-recognition/icon2.svg';
import facial_recognition_third_icon from './../../assets/icons/single-product/facial-recognition/icon3.svg';

import government_issued_card_first_icon from './../../assets/icons/single-product/govt-issued-id-card-similarity/icon1.svg';
import government_issued_card_second_icon from './../../assets/icons/single-product/govt-issued-id-card-similarity/icon2.svg';
import government_issued_card_third_icon from './../../assets/icons/single-product/govt-issued-id-card-similarity/icon3.svg';

import contactless_fingerprint_first_icon from './../../assets/icons/single-product/contactless-fingerprint-capturing/icon1.svg';
import contactless_fingerprint_second_icon from './../../assets/icons/single-product/contactless-fingerprint-capturing/icon2.svg';
import contactless_fingerprint_third_icon from './../../assets/icons/single-product/contactless-fingerprint-capturing/icon3.svg';

import pep_screening_performance_first_icon from './../../assets/icons/single-product/pep-screening-platform/icon1.svg';
import pep_screening_performance_second_icon from './../../assets/icons/single-product/pep-screening-platform/icon2.svg';
import pep_screening_performance_third_icon from './../../assets/icons/single-product/pep-screening-platform/icon3.svg';

import customizable_data_extraction_first_icon from './../../assets/icons/single-product/customizable-data-extraction/icon1.svg';
import customizable_data_extraction_second_icon from './../../assets/icons/single-product/customizable-data-extraction/icon2.svg';
import customizable_data_extraction_third_icon from './../../assets/icons/single-product/customizable-data-extraction/icon3.svg';

// Flow Charts
import flowChart1 from './../../assets/img/products/id_data_extraction_flow_diagram.png';
import flowChart2 from './../../assets/img/products/urdu_transliteration_flow_diagram.png';
import flowChart3 from './../../assets/img/products/signature_verification_flow_diagram.png';
import flowChart4 from './../../assets/img/products/kyc_documents_flow_diagram.png';
import flowChart5 from './../../assets/img/products/facial_recognition_flow_diagram.png';
import flowChart6 from './../../assets/img/products/government_issued_card_flow_diagram.png';
import flowChart7 from './../../assets/img/products/contactless_fingerprint_flow_diagram.png';
import flowChart8 from './../../assets/img/products/pep_screening_performance_flow_diagram.png';
import flowChart9 from './../../assets/img/products/customizable_data_extraction_flow_diagram.png';

export const Products = [
  // 1st Product
  // data_extraction = technology_strategy
  {
    id: 0,
    name: 'id_data_extraction',
    // bannerHeading: 'ID Data Extraction',
    // bannerExplainer:
    //   'Our API extracts Customer Identification Data from a picture format of NADRA issued CNIC and SNIC. The data extracted in text format can be used to prefill Customer Data in Forms during Customer Onboarding.',
    bannerHeading: 'Technology Strategy',
    bannerExplainer:
      'Leverage technology to roll out strategies more efficiently and at scale across various markets. Simplify the intricate process of adopting new technology, processes, and digital solutions.',
    bannerImg: id_card_extraction_banner,
    Product_features: {
      title:
        'Our team will work with you to assess your current technology landscape, identify gaps and opportunities, and develop a roadmap for implementing technology solutions that will align with your digital strategy.',
      subTitle: 'We will help you define how to',
      features: [
        'Identify technology that supports your digital transformation goals',
        'Design and implement scalable and secure technology architecture',
        'Stay up-to-date with the latest emerging technologies - AI, ML, and blockchain',
        'Foster a culture of innovation and identify new opportunities for technology innovation',
        'Develop comprehensive technology transformation approach that work',
        'Account for crucial dependencies that are required to enable the transformation',
      ],
    },
    // Product_features: [
    //   {
    //     featureOne: {
    //       icon: id_data_extraction_first_icon,
    //       title: 'ID Card Detection',
    //       explainer:
    //         'Lower fraud risks and enhance the efficiency of businesses.',
    //     },
    //     featureTwo: {
    //       icon: id_data_extraction_second_icon,
    //       title: 'Text Dectection',
    //       explainer:
    //         'Lower fraud risks and enhance the efficiency of businesses.',
    //     },
    //     featureThree: {
    //       icon: id_data_extraction_third_icon,
    //       title: 'Type of ID Card ',
    //       explainer:
    //         'Lower fraud risks and enhance the efficiency of businesses.',
    //     },
    //   },
    // ],

    productFlowChart: flowChart1,
    useCases: [
      {
        cardOne: {
          title: 'Identify New Opportunities for Technology Innovation',
          explainer:
            'Developed a process for ideation and prototyping, resulting in the launch of new products for a financial services company',
          // title: 'Digital Onboarding',
          // explainer:
          //   'Differentiating Customer Experience by Auto-extracting Data and Pre-filling the Customer Forms.',
        },
        cardTwo: {
          title: 'Leverage Emerging Technologies to Improve Outcomes',
          explainer:
            'Implemented predictive analytics tools to identify risks and personalize plans for a healthcare provider',
          // title: 'Data Cleansing for Financial Institutions',
          // explainer:
          //   'Ensure KYC Data Quality of Customers residing in your Silos.',
        },
        cardThree: {
          title: 'Design Scalable and Secure Technology Architecture',
          explainer:
            'Enabled a retail company in relaunching an e-commerce store to expand online presence and reach new markets ',
          // title: 'Vendor Registration',
          // explainer:
          //   'Vendor Registration in few Clicks with Minimum Human Intervention.',
        },
      },
    ],
  },

  // 2nd Product
  {
    id: 1,
    name: 'urdu_transliteration',
    bannerHeading: 'Urdu Transliteration',
    bannerExplainer:
      'Our API transliterates Customer Data from Urdu to Roman Urdu Format.',
    bannerImg: urdu_transliteration_banner,
    // bannerImg: "{id_data_extraction_banner_img}",
    // button: "Request a demo",
    Product_features: [
      {
        featureOne: {
          icon: urdu_transliteration_first_icon,
          title: 'High Accuracy',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
        featureTwo: {
          icon: urdu_transliteration_second_icon,
          title: 'Transliterated Geo Information',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
        featureThree: {
          icon: urdu_transliteration_third_icon,
          title: 'Multiple Results',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
      },
    ],
    productFlowChart: flowChart2,
    useCases: [
      {
        cardOne: {
          title: 'Chat Bots',
          explainer: 'Urdu to Roman Urdu Transliteration for Chatbots.',
        },
        cardTwo: {
          title: 'Digital Onboarding',
          explainer:
            'Reduce Incorrect Data Inputs by Customers and Simplify their Onboarding Journey.',
        },
        cardThree: {
          title: 'Analyzing Product Reviews',
          explainer:
            'Aggregate Customer Reviews to better understand their Needs.',
        },
      },
    ],
  },
  // 3rd Product
  {
    id: 2,
    name: 'signature_verification',
    bannerHeading: 'Signature Verification',
    bannerExplainer:
      'We are working on Signature Verification API that can compare signatures of a customer with the signatures extracted from NADRA issued CNIC, SNIC, or Signature Cards available with the Financial Institutions (FIs).',
    bannerImg: signature_verification_banner,
    // bannerImg: "{id_data_extraction_banner_img}",
    // button: "Request a demo",
    Product_features: [
      {
        featureOne: {
          icon: signature_verification_first_icon,
          title: 'Signature Identification',
          explainer: 'Works with multiple check designs and layouts.',
        },
        featureTwo: {
          icon: signature_verification_second_icon,
          title: 'Real Time Signature Verification',
          explainer:
            'It saves time significantly as manual verifications are time consuming.',
        },
        featureThree: {
          icon: signature_verification_third_icon,
          title: 'Confidence Score',
          explainer:
            'Continuous learning capabilities for faster verifications over time.',
        },
      },
    ],
    productFlowChart: flowChart3,
    useCases: [
      {
        cardOne: {
          title: 'Lending',
          explainer:
            'Reduce Document handling costs, exceed Compliance requirements, and improve Customer experience for Consumer and Small Business Loans.',
        },
        cardTwo: {
          title: 'Wealth Management',
          explainer:
            'Shorten the long Sales process and reduce the Error rates.',
        },
        cardThree: {
          title: 'Account Opening',
          explainer:
            ' Offer a fully Digital Account opening and Onboarding process from Anywhere.',
        },
      },
    ],
  },
  // 4th Product
  {
    id: 3,
    name: 'kyc_documents',
    bannerHeading: 'KYC Documents Data Extraction',
    bannerExplainer:
      'Our API automatically extracts data from different formats of Know Your Customer (KYC) documents such as proof of current residential address. This extracted data from the documents can be compared with the data provided by the customer.',
    bannerImg: kyc_documents_banner,
    // bannerImg: "{id_data_extraction_banner_img}",
    // button: "Request a demo",
    Product_features: [
      {
        featureOne: {
          icon: kyc_documents_first_icon,
          title: 'Table Detection',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
        featureTwo: {
          icon: kyc_documents_second_icon,
          title: 'Text Dectection',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
        featureThree: {
          icon: kyc_documents_third_icon,
          title: 'Figure Detection',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
      },
    ],
    productFlowChart: flowChart4,
    useCases: [
      {
        cardOne: {
          title: 'Proof of Address',
          explainer:
            'Auto-extract Customer Name and Address from Utility Bills.',
        },
        cardTwo: {
          title: 'Proof of Income',
          explainer: 'Auto-extract Customer Info from Payslips.',
        },
        cardThree: {
          title: 'Legal Documents',
          explainer: 'Auto-extract Data from Legal Documents for KYC purposes.',
        },
      },
    ],
  },
  //   5th Product
  {
    id: 4,
    name: 'facial_recognition',
    bannerHeading: 'Facial Recognition',
    bannerExplainer:
      'Our Facial Recognition API extracts customer,s picture from CNIC and captures real-time picture of the customer along with the liveness testing. The API also provides a similarity score between these two pictures.',
    bannerImg: facial_recognition_banner,
    // bannerImg: "{id_data_extraction_banner_img}",
    // button: "Request a demo",
    Product_features: [
      {
        featureOne: {
          icon: facial_recognition_first_icon,
          title: 'Life Sign Detection',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
        featureTwo: {
          icon: facial_recognition_second_icon,
          title: 'Texture Analysis',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
        featureThree: {
          icon: facial_recognition_third_icon,
          title: 'Emotion Analysis',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
      },
    ],
    productFlowChart: flowChart5,
    useCases: [
      {
        cardOne: {
          title: 'Customer Onboarding',
          explainer: 'Faster Onboarding & Enhanced Customer Experience.',
        },
        cardTwo: {
          title: 'Financial Transactions',
          explainer: 'Secure ID Verification & faster Transactions Approval.',
        },
        cardThree: {
          title: 'Contactless Kiosk and Mobile POS Payment',
          explainer: 'Enable Face biometrics for Payments.',
        },
      },
    ],
  },

  //   6th Product
  {
    id: 5,
    name: 'government_issued_card',
    bannerHeading: 'Government Issued ID Card Similarity',
    bannerExplainer:
      'With our state of the art facial recognition techniques, we provide similarity check on Govt issued cards by comparing the digital photo on the card with a live video of the user to detect scams and verify the genuine users.',
    bannerImg: gov_issued_card_banner,
    // bannerImg: "{id_data_extraction_banner_img}",
    // button: "Request a demo",
    Product_features: [
      {
        featureOne: {
          icon: government_issued_card_first_icon,
          title: 'ID Card Detection',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
        featureTwo: {
          icon: government_issued_card_second_icon,
          title: 'Facial Image Dectection',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
        featureThree: {
          icon: government_issued_card_third_icon,
          title: 'Similarity Score',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
      },
    ],
    productFlowChart: flowChart6,
    useCases: [
      {
        cardOne: {
          title: 'Customer Onboarding',
          explainer: 'Enhanced Security for Digital ID.',
        },
        cardTwo: {
          title: 'eKYC',
          explainer: 'Efficient & Secure Collection of KYC Data.',
        },
        cardThree: {
          title: 'Background Checks',
          explainer: 'Perform Remote Background Checks and Screening.',
        },
      },
    ],
  },
  // 7th Product
  {
    id: 6,
    name: 'contactless_fingerprint',
    bannerHeading: 'Contactless Fingerprint Capturing',
    bannerExplainer:
      'We are working on an API including a framework for mobile platform to capture fingerprints of customers through mobile cameras. These fingerprints can be sent to NADRA for Biometric Verification.',
    bannerImg: contactless_fingerprint_banner,
    // bannerImg: "{id_data_extraction_banner_img}",
    // button: "Request a demo",
    Product_features: [
      {
        featureOne: {
          icon: contactless_fingerprint_first_icon,
          title: 'Truly Mobile',
          explainer: 'Utilizing cameras of standard smartphones.',
        },
        featureTwo: {
          icon: contactless_fingerprint_second_icon,
          title: 'Real time Fingerprint Capturing',
          explainer: 'Saves costs for hardware, software and training.',
        },
        featureThree: {
          icon: contactless_fingerprint_third_icon,
          title: 'Real time Fingerprint Verification',
          explainer:
            'Easily integrated into existing AFIS/ABIS systems and workflows.',
        },
      },
    ],
    productFlowChart: flowChart7,
    useCases: [
      {
        cardOne: {
          title: 'Remote Onboarding',
          explainer:
            'Bridge the last mile hurdle to Onboarding of Remote Customers.',
        },
        cardTwo: {
          title: 'Insurance',
          explainer:
            'Remote Identity Verification to offer Online Insurance and avoid Scams.',
        },
        cardThree: {
          title: 'BNPL',
          explainer:
            'High Confidence in Identity of Your Customers to offer Buy Now Pay Later Services.',
        },
      },
    ],
  },

  // 8th Product
  {
    id: 7,
    name: 'pep_screening_performance',
    bannerHeading: 'PEP Screening Performance',
    bannerExplainer:
      'Our Political Exposed Persons (PEP) Screening platform actively scans and extracts publicly available data through newspapers of a particular PEP. This data is summarized and utilized for Ongoing Due Diligence function of an FI.',
    bannerImg: pep_screening_banner,
    // bannerImg: "{id_data_extraction_banner_img}",
    // button: "Request a demo",
    Product_features: [
      {
        featureOne: {
          icon: pep_screening_performance_first_icon,
          title: 'Scanning Urdu and English News',
          explainer: 'Works with multiple check designs and layouts.',
        },
        featureTwo: {
          icon: pep_screening_performance_second_icon,
          title: 'High Accuracy',
          explainer:
            'It saves time significantly as manual verifications are time consuming.',
        },
        featureThree: {
          icon: pep_screening_performance_third_icon,
          title: 'Auto Generated Summaries',
          explainer:
            'Continuous learning capabilities for faster verifications over time.',
        },
      },
    ],
    productFlowChart: flowChart8,
    useCases: [
      {
        cardOne: {
          title: 'AML/CFT Regulatory Compliance',
          explainer:
            'Automate your Public News Search for PEPs and reduce your Regulatory Compliance load.',
        },
      },
    ],
  },

  // 9th Product
  {
    id: 8,
    name: 'customizable_data_extraction',
    bannerHeading: 'Customizable Data Extraction',
    bannerExplainer:
      'Our API automatically extracts data from different formats of Know Your Customer (KYC) documents such as proof of current residential address. This extracted data from the documents can be compared with the data provided by the customer.',
    bannerImg: customizable_data_banner,
    // bannerImg: "{id_data_extraction_banner_img}",
    // button: "Request a demo",
    Product_features: [
      {
        featureOne: {
          icon: customizable_data_extraction_first_icon,
          title: 'Table Detection',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
        featureTwo: {
          icon: customizable_data_extraction_second_icon,
          title: 'Text Dectection',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
        featureThree: {
          icon: customizable_data_extraction_third_icon,
          title: 'Figure Detection',
          explainer:
            'Lower fraud risks and enhance the efficiency of businesses.',
        },
      },
    ],
    productFlowChart: flowChart9,
    useCases: [
      {
        cardOne: {
          title: 'Sales Receipt',
          explainer: 'Digitize and Aggregate your Sales Data.',
        },
        cardTwo: {
          title: 'Reports',
          explainer:
            'Unlock the Business-critical Data trapped in Unstructured Formats (PDF, DOCX, PRN, etc.).',
        },
        cardThree: {
          title: 'Loan Applications',
          explainer: 'Extract Data from Semi-structured Forms in Real-time.',
        },
      },
    ],
  },
];
