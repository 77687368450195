import React from "react";
import shap from "../assets/img/svg/footer.svg";
import { Link } from "react-router-dom";
import logo from "../assets/img/home5/footer-logo.png";

/// Import Icons

import phoneIcon from "./../assets/icons/phoneIcon.svg";
import locationIcon from "./../assets/icons/locationIcon.svg";
import emailIcon from "./../assets/icons/emailIcon.svg";
import footerTextIcon from "./../assets/icons/footer-text.svg";

const FooterItems = [
  {
    title: "Useful Links",
    links: [
      { linkTitle: "Home", link: "/" },
      { linkTitle: "About Us", link: "/about" },
      // { linkTitle: "Products", link: "/shop" },
      { linkTitle: "Ask Our Experts", link: "/contact" },
      { linkTitle: "We Are Hiring", link: "/we_are_hiring" },
    ],
  },
  // {
  //     title:"Services",
  //     links: [
  //       { linkTitle: "About", link: "/about" },
  //       { linkTitle: "Service", link: "/services" },
  //       { linkTitle: "Contact Us", link: "/contact" },
  //       { linkTitle: "Projects", link: "/projects" },
  //     ],
  // }
];

const Footer = () => {
  return (
    <>
      <footer id='footer' className='pt-30 responsive-pt-0'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-12 col-sm-12 col-12 d-flex justify-content-flex-start padding-responsive'>
              <div className='footer-about-area pt50 d-flex flex-column '>
                <Link to='/'>
                  <center>
                    <img alt='' src={logo} />
                  </center>
                </Link>

                <Link to='/'>
                  <center>
                    {/* <img alt='' src={footerTextIcon} /> */}
                    <p className='footer-text'>Finantler</p>
                  </center>
                </Link>

                {/* <p className='pt30'>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy
                </p> */}
              </div>
            </div>
            {FooterItems.map((item, key) => (
              <div
                className='col-lg-4 col-md-6 col-sm-12 col-12 padding-responsive'
                key={key}>
                <div className='footer-list-widget single-item-mt-3 responsive-mt-60 pb30'>
                  <div className='footer-heading'>
                    <h5>{item.title}</h5>
                  </div>
                  <div className='footer-list pt15'>
                    <ul>
                      {item.links.map((flinks, key) => (
                        <li key={key}>
                          <Link to={flinks.link} style={{ color: "#fff" }}>
                            <p>{flinks.linkTitle}</p>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
            <div className='col-lg-4 col-md-6 col-sm-12 col-12 padding-responsive'>
              <div className='footer-list-widget responsive-mt-15'>
                <div className='footer-heading'>
                  <h5>Contact Info</h5>
                </div>
                <div className='footer-contact-area footer-list pt15 pb30'>
                  <ul>
                    {/* <li>
                      <img src={phoneIcon} alt='phone_icon' />

                      <p>0322-5555451</p>
                    </li> */}
                    <li>
                      <img src={locationIcon} alt='location_icon' />
                      {/* <ImLocation /> */}

                      {/* <Link to='/' style={{ color: "#fff" }}> */}
                      <p>
                        Office # 710, ISE Towers, 55-B
                        <br /> Jinnah Avenue, Blue Area, Islamabad.
                      </p>
                      {/* </Link> */}
                    </li>
                    <li>
                      <img src={emailIcon} alt='email_icon' />
                      {/* <SiMinutemailer /> */}

                      {/* <Link to='/' style={{ color: "#fff" }}> */}
                      <p>info@finantler.com</p>
                      {/* </Link> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='footer-bottom-img'>
          <img alt='' src={shap} />
        </div> */}
      </footer>
    </>
  );
};

export default Footer;
