import React from "react";
import Banner from "../component/About/Banner";
import GetInTouch from "../component/About/GetInTouch";
import Intro from "../component/About/Intro";

const About = () => {
  return (
    <>
      <Banner />
      <Intro />
      <GetInTouch />
    </>
  );
};

export default About;
