import React from 'react';
import img from '../../../assets/img/home5/about.png';
import dataSecurity from '../../../assets/icons/data-security.svg';
import easyDeploymnet from '../../../assets/icons/easy-deployment.svg';

import sitecoreIcon from '../../../assets/icons/services/siteCore.svg';

import salesForceIcon from '../../../assets/icons/services/salesForce.svg';

import { GoCheck } from 'react-icons/go';

const DataCompromise = () => {
  return (
    <>
      <section id='data-those' className='py70'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
              <p className='data-those-header'>Platform Expertise</p>
            </div>
          </div>
          {/* data-compromise-col-first' */}
          {/* data-compromise-col-second */}
          <div className='row pt60 d-flex justify-content-center'>
            <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
              <div className='temp1 data-compromise-col-firs'>
                <img
                  src={sitecoreIcon}
                  alt='data-security'
                  style={{ width: '150px', height: '150px' }}
                />
                <p className='data-those-tag-temp'>Sitecore</p>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12 col-12 '>
              <div className='temp2 data-compromise-col-second'>
                <img
                  src={salesForceIcon}
                  alt='easy-deployment'
                  style={{ width: '150px', height: '150px' }}
                />
                <p className='data-those-tag-temp'>Salesforce</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DataCompromise;
