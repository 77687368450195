import img1 from "../../../assets/img/work-process/1.png";
import img2 from "../../../assets/img/work-process/2.png";
import img3 from "../../../assets/img/work-process/3.png";

export const WorkProcessData = [
  {
    id: "image-one",
    img: img1,
    heading: "AI-Powered Digital Customer Onboarding",
    subheading: "Delight your Customers at the First Touch Point",
    para: "Lorem ipsum dolor sit amet, consect adipiscing elit. Nulla neque, maximus ut accumsan ut, posuere sit ipsum.",
    button: "Read More...",
  },
  {
    id: "image-two",
    img: img2,
    heading: "NLP-Powered Financial Regulatory Compliance",
    subheading: "Track and Find Intent of Public Information related to PEPs",
    para: "Lorem ipsum dolor sit amet, consect adipiscing elit. Nulla neque, maximus ut accumsan ut, posuere sit ipsum.",
    button: "Read More...",
  },
  {
    id: "image-three",
    img: img3,
    heading: "IDP-Powered Unstructured Data Extraction",
    subheading: "Unlock valuable Business Data trapped in documents",
    para: "Lorem ipsum dolor sit amet, consect adipiscing elit. Nulla neque, maximus ut accumsan ut, posuere sit ipsum.",
    button: "Read More...",
  },
];
